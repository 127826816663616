.nav-dropdown-link, .admin-button{
    color: #164f58;
    text-decoration: none;
}

.header-border {
    border-bottom: solid 2px #164f58;
    box-shadow: 0px 3px 10px #00000029;
    background-color: white;
}

.header-menu {
    color: #164f58;
    font-weight: bold;
}

.admin-button {
    display: flex;
}

.admin-button:hover {
    border: 2px solid #164f58;
}