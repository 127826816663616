label {
    display: block;
    color: #0D4F57;
    font-size: 10pt;
}

#container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 30px;
}

.whitebox {
    background-color: #FCFCFC;
    padding: 30px 40px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.whitebox-row {
    background-color: #FCFCFC;
    padding: 30px 40px;
    border-radius: 4px;
    display: flex;
    gap: 20px;
}

.duration-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.disabled {
    opacity: 50%;
}

.day-time-module {
    background-color: #FCFCFC;
    padding: 30px 40px;
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
}

.day-time-module-top-row {
    display: flex;
    align-items: center;
}

.day-time-module-checkbox {
    margin: 0 10px;
}

.day-time-module-header {
    /*grid-row: 1 / 1 ;*/
    /*grid-column: 2 / 2;*/
    display: inline;
    margin: 0;
}

.start-stop-dropdown-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.daily-frequency-button-enabled {
    background-color: #0D4F57;
}

.daily-frequency-button-enabled:hover {
    background-color: #0D4F57;
}

.daily-frequency-button-disabled {
    background-color: #FFFFFF;
    color: #BABABA;
}

.daily-frequency-button-disabled:hover {
    background-color: #FFFFFF;
    color: #BABABA;
}

.daily-frequency-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.submitButton {
    padding: 0 36px;
    color: #FFFFFF;
    background-color: #6B9B3B;
    margin-bottom: 30px;
    border-radius: 23px;
}

.addButton:hover {
    background-color: #588032
}

.cancel-button {
    padding: 0 36px;
    margin-left: 10px;
    margin-bottom: 30px;
    border-radius: 23px;
}

.trigger-rows {
    padding: 10px 0;
}

.copy-icon {
    cursor: pointer;
}

.api-card {
    margin-bottom: 20px;
}

.form-checkbox-label {
    font-size: 11pt;
}

.max-header {
    margin: 0;
}

.centered {
    text-align: center;
}
