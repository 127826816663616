.whitebox-reports {
    background-color: #FCFCFC;
    padding: 20px 15px;
    border-radius: 4px;
    display: flex;
    gap: 20px;
}

.no-messages-indicator {
    text-align: center;
    margin: 30px;
    color: #0D4F57;
}