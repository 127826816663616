table {
    width: 100%;
    font: normal normal 900 18px/25px Arial;
    margin-top: 33px;
}

th {
    background-color: #0D4F57;
    color: #FFFFFF;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 70px;
    font: normal normal medium Arial;
}

th:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

th:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

td {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 70px;
    font: normal normal medium Arial;
}

tr:nth-child(even) {
    background-color: #F4FAFC;
}

tr:nth-child(odd) {
    background-color: #FFFFFF;
}

#sms-container {
    margin: 30px;
    padding-bottom: 30px;
}

.rectangle {
    background-color: #0D4F57;
    height: 24px;
    border-radius: 3px;
    width: 100%;
}

.circle {
    border-radius: 50%;
    display: flex;
}

.marketingHeader {
    width: 100%;
}

.reportButton, .reportButton:hover {
    padding: 0 36px;
    color: #FFFFFF;
    background-color: #0D4F57;
    flex: auto;
    border-radius: 23px;
}

.addButton, .addButton:hover{
    padding: 0 36px;
    color: #FFFFFF;
    flex: auto;
    background-color: #6B9B3B;
    border-radius: 23px;
}

td img {
    height: 25px;
    width: 25px;
}

.journey-button-span {
    vertical-align: middle;
}

.button-spinner {
    width: 25px;
    height: 25px;
    margin: 6px 13px;
}